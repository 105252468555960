import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import GlobalStyle from './styles/styledGlobal';
import City from './pages/City';
import Demo from './components/Demo';
import data from './data';
import NavigationStateProvider from './contexts/navigationState';

const { REACT_APP_AI_KEY } = process.env;
let reactPlugin = null;

if (REACT_APP_AI_KEY) {
  reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: REACT_APP_AI_KEY,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}

const App = () => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <NavigationStateProvider>
        <GlobalStyle />
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path="/office" element={<Demo data={data.office} />} />
              <Route path="/finance" element={<Demo data={data.finance} />} />
              <Route path="/healthcare" element={<Demo data={data.healthcare} />} />
              <Route path="/" element={<City />} />
            </Routes>
          </BrowserRouter>
        </div>
      </NavigationStateProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
