import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import Logo from '../assets/SailPoint-Logo.svg';
import {
  ButtonText,
  CloseButton,
  CloseButtonIcon,
  RelativeWrapperWelcomePopUp,
  StartButton,
  WelcomeContainer,
  WelcomeDescription,
  WelcomeLogo,
  WelcomeTitle,
  WelcomeTooltip
} from './styled';
import CloseIcon from '../assets/CloseIcon.svg';

export const WelcomePopup = ({ visible, onClose, onDemo }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(true);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setOpened(true);
    }, 1500);

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  useEffect(() => {
    if (!visible) {
      onClick();
    }
  }, [visible]);

  const onClick = () => {
    setClosing(false);
    setOpened(false);
    setTimeout(onClose(), 1500);
  };

  return (
    <>
      <WelcomeContainer opened={opened} closing={closing}>
        <RelativeWrapperWelcomePopUp>
          <CloseButton>
            <CloseButtonIcon src={CloseIcon} onClick={onClick} />
          </CloseButton>
          <WelcomeLogo>
            <Svg src={Logo} />
          </WelcomeLogo>
          <WelcomeTooltip>INTERACTIVE DEMOS</WelcomeTooltip>
          <WelcomeTitle>Get hands on with SailPoint </WelcomeTitle>
          <WelcomeDescription>
            Identity security is business essential for modern enterprises. But the ability to do it effectively has
            moved well beyond human capacity. That’s where SailPoint stands out. Our identity security cloud platform is
            made for the sophisticated needs of today’s modern enterprise, delivering an intelligent, autonomous
            identity foundation that securely fuels your business.
          </WelcomeDescription>
          <StartButton onClick={onClick} centered={!onDemo}>
            <ButtonText>Start exploring</ButtonText>
          </StartButton>
        </RelativeWrapperWelcomePopUp>
      </WelcomeContainer>
    </>
  );
};

WelcomePopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default WelcomePopup;

WelcomePopup.defaultProps = {
  onDemo: false
};
