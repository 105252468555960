import styled, { css, keyframes } from 'styled-components';
import Svg from 'react-inlinesvg';

export const DescriptionAnimation = (height) => keyframes`
  0% { height: 10px; width: 0px; left: 15px; }
  10% { height: 20px; width: 20px; left: 8px; }
  50% { height: 20px; width: 280px; left: -120px; }
  100% { height: ${height}px; width: 280px; left: -120px; }
`;

export const DescriptionAnimationOut = (height) => keyframes`
  0% { height: ${height}px; width: 280px; left: -120px; }
  50% { height: 20px; width: 280px; left: -120px; }
  90% { height: 20px; width: 20px; left: 8px; }
  100% { height: 10px; width: 0px; left: 15px; }
`;

export const BlurAnimationIn = keyframes`
  0% { filter: blur(0px); }
  25% { filter: blur(0.5px); }
  50% { filter: blur(1px); }
  75% { filter: blur(1.5px); }
  100% { filter: blur(2px); }
`;

export const BlurAnimationOut = keyframes`
  0% { filter: blur(2px); }
  25% { filter: blur(1.5px); }
  50% { filter: blur(1px); }
  75% { filter: blur(0.5px); }
  100% { filter: blur(0px); }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const imageContainerStyle = {
  overflow: 'hidden',
  width: '100vw',
  aspectRatio: '16 / 9',
  backgroundColor: '#485372'
};

export const zoomFadeIn = keyframes`
  0% {
    filter: blur(0px);
    transform: scale(1);
  }
  25% {
    filter: blur(0.5px);
  }
  50% {
    filter: blur(1px);
  }
  75% { 
    filter: blur(1.5px); 
  }
  100% {
    transform: scale(3.5);
    opacity: 1;
    filter: blur(3px);
  }
`;

export const zoomFadeOut = keyframes`
  0% {
    transform: scale(3); 
    filter: blur(3px);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
`;

export const BackgroundImageZoom = styled.img`
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: fill;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  transform-origin: ${(props) => `${props.origin.x} ${props.origin.y}`};
  animation: ${(props) =>
    props.zoomed
      ? css`
          ${zoomFadeIn} 0.8s ease-in-out forwards
        `
      : props.zoomOut
      ? css`
          ${zoomFadeOut} 0.8s ease-in-out forwards
        `
      : 'none'};
`;

export const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  animation-duration: 0.75s;
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

export const RelativeWrapper = styled.div`
  position: relative;
  aspect-ratio: 16/9;
`;

export const LogoContainer = styled.div`
  position: absolute;
  margin-left: 0px;
  background-color: #ffffff;
  top: 30px;
  width: 310px;
  height: 64px;
  border-radius: 0px 40px 40px 0px;
  padding: 9px 49px 72px 24px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  animation-duration: 0.75s;
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

export const BackToTheCityContainer = styled.div`
  position: absolute;
  margin-left: 0px;
  background-color: #cc27b0;
  top: 135px;
  left: 30px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  gap: 10px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

export const Button = styled.div`
  width: 50px;
  border-radius: 50%;
  opacity: 1;
  cursor: ${(props) => (props.pointerVisibility ? 'pointer' : 'auto')};
`;

export const ClickContainer = styled.div`
  position: fixed;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  width: 50px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  animation-duration: 0.75s;
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
  display: flex;
  flex-direction: ${(props) => {
    switch (props.position) {
      case 'LEFT':
        return 'row';
      case 'RIGHT':
        return 'row-reverse';
      case 'TOP':
        return 'column';
      case 'BOTTOM':
        return 'column-reverse';
    }
  }};
  z-index: ${(props) => (props.blurred ? `0` : '1')};
`;

export const ClickContainerCity = styled.div`
  position: fixed;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  width: 50px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  animation-duration: 0.75s;
  display: flex;
  flex-direction: ${(props) => {
    switch (props.position) {
      case 'LEFT':
        return 'row';
      case 'RIGHT':
        return 'row-reverse';
      case 'TOP':
        return 'column';
      case 'BOTTOM':
        return 'column-reverse';
    }
  }};
  z-index: 0;
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

export const NameContainer = styled.div`
  position: fixed;
  top: calc(${(props) => props.top}% + 60px); // calc the circle dimensions
  left: calc(${(props) => props.left}% + 25px); // calc the circle dimensions
  width: 250px;
  text-align: center;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 30px;
  background-color: #00339f;
  z-index: ${(props) => (props.blurred ? `0` : '1')};
  transform: translateX(-50%);
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

export const SettingsContainer = styled.div`
  position: absolute;
  left: 30px;
  top: calc(100vh - 90px);
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50px 50px 50px 0px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
  animation-duration: 0.75s;
  cursor: ${(props) => (props.pointerVisibility ? 'pointer' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Listings = styled.div`
  position: absolute;
  right: 30px;
  top: calc(100vh - 90px);
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50px 50px 0px 50px;
  filter: ${(props) => (props.blurred ? `blur(${props.blurRatio}px)` : 'blur(0px)')};
  animation-name: ${(props) => (props.blurred ? BlurAnimationIn : BlurAnimationOut)};
  animation-duration: 0.75s;
  cursor: ${(props) => (props.pointerVisibility ? 'pointer' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.blurred ? 'none' : 'auto')};
`;

const drillDownAnimation = keyframes`
  0% { height: 10px; width: 10px;   }
  10% { height: 10px; width: 10px;  }
  50% { height: 10px; width: 80%;   }
  100% { height: 84vh; width: 80%;  }
`;

const reverseDrillDownAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.1); opacity: 0; }
`;

export const DemoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.fullHeight ? '100vh' : 'inherit')};
`;

export const WrapDrillDown = styled.div`
  height: 84vh;
  display: flex;
  flex-direction: column;
  content-visibility: ${(props) => (props.opened ? 'auto' : 'hidden')};
  background-color: #ffffff;
  z-index: 1;
  width: ${(props) => (props.fullScreen ? '100%' : '80%')};
  background-color: #fff;
  border-color: white;
  position: relative;
  -webkit-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 36.8px 3.2px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 50px 60px;
  margin: auto auto;
  transform-origin: center center;
  animation-name: ${(props) => (props.closing ? drillDownAnimation : reverseDrillDownAnimation)};
  animation-duration: 1.5s;
`;

export const DrillDownColumnA = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const DrillDownColumnB = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DrillDownCircle = styled.div`
  width: 85%;
  height: auto;
  aspect-ratio: 1/1;
  background-color: #f2f5f7;
  border-radius: 100%;
  display: flex;
  justify-content: center;
`;

export const DrillDownHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DrillDownMain = styled.span`
  display: flex;
  gap: 80px;
  height: 100%;
`;

export const DrillDownClose = styled(Svg)`
  position: absolute;
  right: 30px;
  top: 30px;
`;

export const DrillDownVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
`;

// Welcome Popup

const welcomeAnimation = keyframes`
  0% { height: 10px; width: 10px;  }
  10% { height: 10px; width: 10px;  }
  50% { height: 10px; width: 670px; }
  100% { height: 731px; width: 670px; }
`;

const preDemoAnimation = keyframes`
  0% { height: 10px; width: 10px;  }
  10% { height: 10px; width: 10px;  }
  50% { height: 10px; width: 670px; }
  100% { height: 683px; width: 670px; }
`;

const reverseWelcomeAnimation = keyframes`
  0% { height: 70%; width: 670px; top: 15%;}
  50% { height: 10px; width: 670px; left: 32%; top: 49%; }
  90% { height: 10px; width: 10px; left: 49%; top: 49%; }
  100% { height: 10px; width: 10px; left: 49%; top: 49%; }
`;

export const WelcomeContainer = styled.div`
  width: 625px;
  background-color: #ffffff;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  content-visibility: ${(props) => (props.opened ? 'auto' : 'hidden')};
  animation-name: ${(props) => (props.closing ? welcomeAnimation : reverseWelcomeAnimation)};
  animation-duration: 1.5s;
  z-index: 1;
`;

export const RelativeWrapperWelcomePopUp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 55px 58px 60px;
`;

export const PreDemoPopupContainer = styled.div`
  width: 625px;
  background-color: #ffffff;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  content-visibility: ${(props) => (props.opened ? 'auto' : 'hidden')};
  animation-name: ${(props) => (props.closing ? [preDemoAnimation] : reverseWelcomeAnimation)};
  animation-duration: 1.5s;
  z-index: 1;
`;

export const WelcomeTooltip = styled.span`
  width: 164px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.14;
  letter-spacing: 1.4px;
  text-align: left;
  color: #415364;
  white-space: nowrap;
  margin-bottom: 30px;
`;

export const WelcomeTitle = styled.span`
  margin: 0px 0px 0 0px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: #0033a1;
  margin-bottom: 30px;
`;

export const WelcomeDescription = styled.span`
  margin: 0px 0px 0 0px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.18px;
  text-align: left;
  margin-bottom: 40px;
  color: #415364;
`;

export const StartButton = styled.button`
  width: 200px;
  height: 50px;
  margin: ${(props) => (props.centered ? '6px auto 0 auto' : '0px')};
  border-radius: 4px;
  border: solid 0 #0033a1;
  background-color: #0033a1;
  cursor: pointer;
  &:hover {
    background-color: #005daa;
  }
`;

export const ButtonText = styled.span`
  width: 127px;
  height: 19px;
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font-weight: 600;
`;

export const WelcomeLogo = styled.div`
  width: 285px;
  margin: 0px 0 15px -20px;
`;

export const CloseButton = styled.div`
  width: 17px;
  position: absolute;
  cursor: pointer;
  top: 40px;
  right: 40px;
`;

export const CloseButtonIcon = styled(Svg)`
  path {
    fill: #415364;
  }
`;
